import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(11),
      marginBottom: theme.spacing(20),
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(4),
      },
    },
    titleContainer: {
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        textAlign: "left",
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      maxWidth: "792px",
      margin: "0 auto",
    },
    faq: {
      marginTop: theme.spacing(10),
    },
    formBlock: {
      marginTop: theme.spacing(20),
    },
    formSubtitle: {
      maxWidth: "540px",
    },
  });
