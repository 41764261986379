import { createStyles, Theme } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      width: "995px",
      maxWidth: "100%",
      margin: "0 auto",
    },
    panel: {
      borderRadius: 0,
      boxShadow: "none",
    },
    noSidePadding: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    question: {
      margin: theme.spacing(5, 0),
      "&$questionExpanded": {
        margin: theme.spacing(5, 0, 2),
      },
    },
    questionExpanded: {},
    dropdownIcon: {
      color: theme.palette.primary.main,
    },
    panelDivider: {
      "&:before": {
        display: "none",
      },
    },
    answer: {
      width: "880px",
      maxWidth: "100%",
      "& > span": {
        display: "block",
      },
    },
  });
