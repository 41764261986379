import { WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";
import React from "react";
import { T, withLocale, WithLocale } from "react-targem";

import Link from "@src/components/Link";

import AccordionItem from "./AccordionItem";
import { styles } from "./FAQ.styles";

export type FAQProps = WithStyles<typeof styles> &
  WithLocale & {
    className?: string;
  };

class FAQ extends React.PureComponent<FAQProps> {
  public render() {
    const { classes, className, locale } = this.props;

    return (
      <div className={cn(className, classes.container)}>
        <AccordionItem
          title={<T message="What is Trucknet?" />}
          content={
            locale === "ro" ? (
              "Aplicatia Trucknet va ofera posibilitatea de a va optimiza rutele, de a salva timp, resurse, si  bani datorita tuturor serviciilor integrate total; gasite in mod uzual in diverse forme si medii diferite (documente digitale, vizibilitate totala la cerere pentru toate tertele, gasire marfa/camion in mod automat)."
            ) : (
              <>
                <T message="Trucknet is an automatic marketplace that allows secure transactions between transport companies. Trucknet is a cloud platform based on transport optimization." />{" "}
                <T message="It offers intelligent intercompany freight exchanges to reduce costs, improve efficiency and profitability while reducing the impact on the environment." />
              </>
            )
          }
        />
        <AccordionItem
          title={<T message="Are you a Freight Exchange?" />}
          content={
            <>
              <T message="No. The main freight exchanges on the market are manual and non-automatic." />{" "}
              <T message="Our solution is fully automated, connected to telematics and TMS." />{" "}
              <T message="Trucknet automatically offers you matches for your empty rides; allows you to track your freight; uses smart planning and digital documents with no extra cost." />{" "}
              <T message="Being a marketplace our solution allows the transaction between carriers directly via our platform, which a simple freight exchange does not allow." />
            </>
          }
        />
        <AccordionItem
          title={
            <T message="I use a freight exchange that is enough for me. What more do you bring?" />
          }
          content={
            <>
              <T message="Trucknet provides an automatic tool with benefits such as analytical information, tracking by geolocation, integration with your Telematics, an automated schedule, a digital CMR, secure online payment, etc." />{" "}
              <T message="Trucknet's algorithms find optimization options not yet offered by conventional freight exchanges." />
            </>
          }
        />
        <AccordionItem
          title={
            <T message="I do not have telematics. How can I track my vehicles?" />
          }
          content={
            <T
              message={
                "We have an application for Android and IOS that drivers download for free to stay in constant contact with their operations manager. The positioning data is transmitted in real-time to their manager."
              }
            />
          }
        />
        <AccordionItem
          title={<T message="How many carriers do you have?" />}
          content={
            <T message="We have registered more than 5,000 transport and logistics companies." />
          }
        />
        <AccordionItem
          title={
            <T message="Do you guarantee the confidentiality of my personal data?" />
          }
          content={
            <T message="We respect the RGPD, the data of our customers are protected and secure." />
          }
        />
        <AccordionItem
          title={<T message="In which countries do you work?" />}
          content={
            <>
              <T message="Trucknet is developing all over Europe." />{" "}
              <T message="We started in France and currently, we also work in Romania, Italy, Spain, England, Portugal, Germany, etc." />
            </>
          }
        />
        {locale !== "he" && (
          <>
            <AccordionItem
              title={
                <T message="How can I be sure that I will be paid by the contracting company after the subcontractor delivery is completed?" />
              }
              content={
                <>
                  <T message="If you wish to have a bank guarantee on your payment to the subcontractor, you can use a solution provided by SSP for the money transfer. The payment will be secured and guaranteed through our partner bank, Crédit Mutuel CIC that will verify the solvency of the company and will guarantee payment to the carrier." />{" "}
                  <T message="For each match played and realized, Trucknet takes a commission of 5%." />
                </>
              }
            />
            <AccordionItem
              title={<T message="How are my payments guaranteed?" />}
              content={
                <T message="The payment will be secured and guaranteed through our partner Crédit Mutuel CIC by SSP which verifies the solvency of the company and guarantees payment to the carrier." />
              }
            />
            <AccordionItem
              title={<T message="Why do I need to give my IBAN?" />}
              content={
                <T message="The IBAN of your company is necessary for payment between carriers. It is needed so you can receive payment corresponding to the transport carried out." />
              }
            />
          </>
        )}
        <AccordionItem
          title={<T message="How an automated match is found?" />}
          content={
            <T message="An automated match is made by our algorithm, or in a few words, when there is an existent cargo and a truck that needs pairing, because there are similarities between those two. (the truck needs cargo/ the cargo needs a truck, route, date and hours, compatibilities between truck/semi-trailer and cargo)." />
          }
        />
        <AccordionItem
          title={<T message="How is the roster used?" />}
          content={
            <T message="The roster is accessed from the main navigation panel. In this planning, the transportation company adds the rides that are already created out of the system. Why would someone do that? Because it offers different opportunities (visibility for all parties involved, status digital tracking, a better view of what needs to be organized). In order to add rides, press “Add ride”, where the ride details will be imputed, the driver, as well as the client that owns the cargo in order to offer them the visibility service." />
          }
        />
        <AccordionItem
          title={<T message="How a transport order is added?" />}
          content={
            <T message="You will need to complete an excel file with the details of the transport order (route, date and hours, the type of the cargo, license plate of the vehicle/trailer, the name of the driver etc.). Then the file is uploaded in the system via “Add transport/delivery”-> “Subcontractor” -> “Upload excel file”. If the upload is successful, you will then open the “Messages” section and you will sign an order using a digital signature. The signed orders can be found in the dashboard." />
          }
        />
        <AccordionItem
          title={
            <T message="What about the drivers that dont have an email address or a smartphone. How they can use the app?" />
          }
          content={
            <T message="One of the ways is by using a domain provider that accepts the creation of temporary email accounts  (guerillamail). If you require more information, contact the technical support team in order to receive a guide that contains all the necessary details. An android/IOS mobile phone is required to use the mobile application." />
          }
        />
        <AccordionItem
          title={
            <T message="How can I offer visibility to the client that is owner of the cargo?" />
          }
          content={
            <T message="If the client has been added in the “Settings” section, then he can be selected when a ride is added in the roster, when a transport order excel file is written or when two companies sign an agreement." />
          }
        />
        <AccordionItem
          title={
            <T message="Where I can follow the reports of the finished rides?" />
          }
          content={
            <T message="The ride details can be viewed more detailed in the “History” section that can be found in the main navigation panel. The general ride details can be viewed in the “Dashboard” section, also found in the main panel." />
          }
        />
        <AccordionItem
          title={<T message="Is my personal data kept safe?" />}
          content={
            <T message="Your personal data are encrypted in the AWS system (Amazon web services)." />
          }
        />
        <AccordionItem
          title={
            <T message="If i own a GPS System and/or TMS/ERP, is there a way to integrate them into the Trucknet System?" />
          }
          content={
            <T message="The GPS System, if available, it can be integrated in the “Settings” -> “External Services”. If it can’t be added, a request to the IT team can be made, so the integration can be established manually (for GPS, TMS, ERP)." />
          }
        />
        <AccordionItem
          title={
            <T message="Why it is needed that all the transport requests must be uploaded to the system?" />
          }
          content={
            <T message="It is needed for all the transport requests to be uploaded, in order to supply the system with the necessary rides. If the system is supplied, then more transport companies will be attracted by the system, which means that the ones who upload the cargo will get matches more quickly." />
          }
        />
        <AccordionItem
          title={
            <T message="The excel file that contains the transport order data fails to be uploaded." />
          }
          content={
            <T message="In this case, verify the imputed data. In most cases, the data in the file is wrong. In case the problem cannot be solved, the romanian technical support team can aid with the file." />
          }
        />
        <AccordionItem
          title={<T message="No matches found between posted truck/cargo." />}
          content={
            <>
              <T message="-the dates do not match, the hour difference is to big (12 h max);" />
              <br />
              <T message="-the location MUST contain a specific city (not country/county);" />
              <br />
              <T message="-the vehicle, semitrailer and cargo are matchable depending on their categories and regulations from real life (example the ADR goods cannot be transported by every vehicle)." />
            </>
          }
        />
        <AccordionItem
          title={
            <T message="I met technical dificulties. Where can I address them?" />
          }
          content={
            <T message="In this case, please send the problem details accompanied by a screenshot/photo to the technical support team." />
          }
        />
        <AccordionItem
          title={<T message="If I have questions, who should I contact?" />}
          content={
            <>
              <T message="Our technical service is available from 8am to 6pm (CET), Monday to Thursday and from 8am to 1pm on Friday. In addition, you can leave us a message on the Support Chat of our platform or send an email to" />{" "}
              <Link external to="mailto:support@trucknet.io">
                support@trucknet.io
              </Link>
              . <T message="We will respond as soon as possible." />
            </>
          }
        />
      </div>
    );
  }
}

export default withLocale(withStyles(styles)(FAQ));
