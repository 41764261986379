import React from "react";

import SeoHead from "@src/components/SeoHead";
import IndexLayout from "@src/layouts/IndexLayout";
import { PageProps } from "@src/types";
import { t } from "@src/utils/translations";

import SupportTemplate from "./SupportTemplate";

export interface SupportPageProps extends PageProps {}

class SupportPage extends React.Component<SupportPageProps> {
  public render() {
    return (
      <IndexLayout {...this.props}>
        <SeoHead
          title={t("How can we help you?")}
          description={t(
            "Our support team is here to help; we want  our clients to experience the best service while using Trucknet.",
          )}
          keywords={[
            t("carriers"),
            t("support"),
            t("faq"),
            t("garantee"),
            t("iban"),
            t("payments"),
            t("questions"),
          ]}
        />
        <SupportTemplate />
      </IndexLayout>
    );
  }
}

export default SupportPage;
