import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";
import React from "react";

import { styles } from "./AccordionItem.styles";

export interface AccordionItemProps extends WithStyles<typeof styles> {
  title: React.ReactNode;
  content: React.ReactNode;
}

class AccordionItem extends React.PureComponent<AccordionItemProps> {
  public render() {
    const { classes, title, content } = this.props;

    return (
      <div className={classes.wrapper}>
        <ExpansionPanel
          className={classes.panel}
          classes={{ root: classes.panelDivider }}>
          <ExpansionPanelSummary
            expandIcon={<ArrowDropDown className={classes.dropdownIcon} />}
            className={classes.noSidePadding}
            classes={{
              content: classes.question,
              expanded: classes.questionExpanded,
            }}>
            <Typography variant="h6">{title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.noSidePadding}>
            <Typography variant="body1" className={classes.answer}>
              {content}
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider />
      </div>
    );
  }
}

export default withStyles(styles)(AccordionItem);
