import React from "react";

import {
  Container,
  Typography,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import { T } from "react-targem";
import { styles } from "./SupportTemplate.styles";

import FormBlock from "@src/components/FormBlock";
import FAQ from "./FAQ";

export interface SupportTemplateProps extends WithStyles<typeof styles> {}

class SupportTemplate extends React.Component<SupportTemplateProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Container className={classes.container}>
        <div className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            <T message="Hello, how can we help you?" />
          </Typography>
          <Typography variant="h5" className={classes.subtitle}>
            <T message="Our support team is here to help; we want our clients to experience the best service while using Trucknet." />
          </Typography>
        </div>
        <FAQ className={classes.faq} />
        <FormBlock
          title={<T message="Couldn’t find an answer to your question?" />}
          subtitle={
            <T message="Please fill out the form below with an explanation and we will do our best to answer." />
          }
          subtitleClassName={classes.formSubtitle}
          className={classes.formBlock}
        />
      </Container>
    );
  }
}

export default withStyles(styles)(SupportTemplate);
